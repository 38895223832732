@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@600&display=swap');

.tech-contact-wrapper {
  position: relative;
  max-width: 500px;
  margin: 0 auto;
  padding: 40px 20px;
  background: linear-gradient(145deg, #0b0f16, #121821);
  border: 2px solid rgba(0, 255, 255, 0.25);
  border-radius: 14px;
  text-align: center;
  color: #ccf9ff;
  font-family: 'Orbitron', sans-serif;
  animation: fadeIn 0.5s ease-in-out;
  box-shadow:
    0 0 20px rgba(0, 255, 255, 0.1),
    inset 0 0 30px rgba(0, 255, 255, 0.03);
}

/* ❌ Close Button */
.close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  background: transparent;
  border: none;
  font-size: 1.4rem;
  color: #00ffff;
  cursor: pointer;
  transition: transform 0.2s ease, color 0.2s ease;
}

.close-btn:hover {
  transform: scale(1.2);
  color: #00ffcc;
}

/* 🧠 Titel */
.tech-contact-wrapper h2 {
  font-size: 2rem;
  margin-bottom: 16px;
  color: #00ffff;
  text-shadow: 0 0 12px rgba(0, 255, 255, 0.3);
}

/* 🧾 Introtext */
.tech-intro {
  font-size: 1rem;
  color: #aafaff;
  margin-bottom: 24px;
  line-height: 1.6;
  text-shadow: 0 0 4px rgba(0, 255, 255, 0.1);
}

/* 🛰 Ikonrad */
.tech-contact-icons {
  display: flex;
  justify-content: center;
  gap: 30px;
}

/* 💠 Ikonstil */
.icon {
  font-size: 2.8rem;
  color: #00ffff;
  text-decoration: none;
  transition: transform 0.25s ease, text-shadow 0.25s ease;
  cursor: pointer;
}

.icon:hover {
  transform: scale(1.2);
  text-shadow: 0 0 14px #00ffff;
}

/* ✨ Fade-in Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 📱 Responsiv */
@media (max-width: 500px) {
  .icon {
    font-size: 2.4rem;
  }

  .tech-contact-wrapper h2 {
    font-size: 1.6rem;
  }

  .tech-intro {
    font-size: 0.95rem;
  }
}
