@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@600&display=swap');

.tech-about-wrapper {
  padding: 40px 28px;
  background: linear-gradient(160deg, #111319, #0a0c12);
  border: 2px solid rgba(0, 255, 255, 0.15);
  border-radius: 16px;
  color: #d3f9ff;
  font-family: 'Orbitron', sans-serif;
  max-width: 950px;
  margin: 60px auto;
  position: relative;
  box-shadow:
    0 0 30px rgba(0, 255, 255, 0.08),
    inset 0 0 40px rgba(0, 255, 255, 0.07);
  animation: techFadeIn 0.5s ease-in-out;
  backdrop-filter: blur(6px);
}

/* Close Button */
.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: rgba(0, 255, 255, 0.06);
  border: 1px solid rgba(0, 255, 255, 0.3);
  color: #00faff;
  border-radius: 8px;
  font-size: 18px;
  padding: 6px 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(4px);
}

.close-btn:hover {
  background: rgba(0, 255, 255, 0.1);
  box-shadow: 0 0 10px #00eaffaa;
}

.about-content {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex-wrap: wrap;
}

/* Profilbild */
.profile-img {
  width: 160px;
  height: 160px;
  object-fit: cover;
  border-radius: 10px;
  border: 2px solid rgba(0, 255, 255, 0.3);
  box-shadow: 0 0 16px rgba(0, 255, 255, 0.2);
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}
.profile-img.loaded {
  opacity: 1;
}

/* Textinformation */
.text-info {
  flex: 1;
  min-width: 260px;
}

.text-info h2 {
  margin-bottom: 16px;
  font-size: 2rem;
  color: #00e6ff;
  text-shadow: 0 0 12px rgba(0, 255, 255, 0.4);
}

.text-info p {
  font-size: 1.05rem;
  line-height: 1.6;
  color: #ccf8ff;
  margin-bottom: 18px;
  text-shadow: 0 0 6px rgba(0, 128, 255, 0.08);
}

/* Ladda ner CV-knapp */
.download-btn {
  display: inline-block;
  margin-top: 12px;
  padding: 12px 24px;
  background: rgba(0, 255, 255, 0.06);
  border: 2px solid #00ffff;
  border-radius: 10px;
  color: #c2f7ff;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.25s ease;
  text-shadow: 0 0 6px #00fff6aa;
}

.download-btn:hover {
  background: rgba(0, 255, 255, 0.1);
  transform: scale(1.05);
  box-shadow: 0 0 18px #00fff7cc;
}

/* Animation */
@keyframes techFadeIn {
  from {
    opacity: 0;
    transform: translateY(10px) scale(0.98);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

/* 📱 Responsiv stil */
@media (max-width: 600px) {
  .about-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .profile-img {
    width: 120px;
    height: 120px;
  }

  .text-info h2 {
    font-size: 1.6rem;
  }

  .text-info p {
    font-size: 0.95rem;
  }

  .download-btn {
    padding: 10px 20px;
  }
}
