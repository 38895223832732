@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@600&display=swap');

html, body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: #000;
  width: 100%;
  height: 100%;
  font-family: 'Orbitron', sans-serif;
  cursor: none;
  color: #ccfaff;
}

/* ================================
   🌌 Mouse Trail Effekt
================================ */
.trail-dot {
  position: fixed;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: radial-gradient(circle, #00ffff 0%, #002f2f 100%);
  pointer-events: none;
  z-index: 100;
  filter: blur(6px) drop-shadow(0 0 12px #00ffffaa);
  animation: pulseTrail 0.6s ease-out infinite;
  transition: transform 0.2s, opacity 0.2s ease;
}

@keyframes pulseTrail {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.3);
    opacity: 0.6;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.3;
  }
}

/* ================================
   🔘 Meny & Navigation
================================ */
.portfolio-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  pointer-events: none;
}

.nav-links {
  position: relative;
  width: 100%;
  height: 100%;
}

.nav-item {
  position: absolute;
  pointer-events: all;
  padding: 14px 28px;
  border: 2px solid rgba(0, 255, 255, 0.25);
  border-radius: 12px;
  background: linear-gradient(145deg, #111921, #0c1118);
  backdrop-filter: blur(2px);
  color: #00ffff;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-shadow: 0 0 6px #00ffff44;
  box-shadow:
    inset 0 0 8px rgba(0, 255, 255, 0.15),
    0 0 14px rgba(0, 255, 255, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
}

.nav-item:hover {
  background: linear-gradient(145deg, #121c26, #101820);
  border-color: #00ffff;
  color: #ffffff;
  box-shadow:
    0 0 20px rgba(0, 255, 255, 0.3),
    inset 0 0 10px rgba(0, 255, 255, 0.2);
  transform: scale(1.05);
  text-shadow: 0 0 12px #00ffffaa;
}

.nav-item.active {
  background: #0e1a21;
  border-color: #00f0ff;
  color: #ffffff;
  box-shadow:
    inset 0 0 14px #00f0ffaa,
    0 0 12px #00f0ffaa;
  transform: scale(1.08);
}

/* 🎯 Menyplacering */
.nav-item.about     { top: 160px; left: 180px; }
.nav-item.education { top: 200px; right: 140px; }
.nav-item.experience { bottom: 280px; left: 130px; }
.nav-item.contact   { bottom: 300px; right: 160px; }

/* ================================
   🧾 Footer – med struktur
================================ */
.footer-info {
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0 -2px 10px rgba(0, 255, 255, 0.2);
  border-top: 1px solid rgba(0, 255, 255, 0.15);
  padding: 12px 0;
  backdrop-filter: blur(6px);
  position: fixed;
  bottom: 0;
  z-index: 50;
  font-size: 13px;
  color: #aefbff;
}

/* Grid-struktur för desktop */
.footer-grid {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  padding: 0 24px;
  max-width: 1024px;
  margin: 0 auto;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.footer-section {
  flex: 1 1 200px;
  min-width: 180px;
}

/* Länkar */
.footer-links a {
  display: block;
  margin: 4px 0;
  color: #00ffff;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.2s ease;
}

.footer-links a:hover {
  text-shadow: 0 0 6px #00ffff;
  transform: translateX(2px);
}

/* Metadata */
.footer-meta {
  font-size: 12px;
  color: #66f2ff;
  text-align: right;
}

/* ✅ Mobilanpassning */
@media (max-width: 600px) {
  .footer-grid {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 12px;
    padding: 10px 16px;
  }

  .footer-meta {
    text-align: center;
    font-size: 11px;
  }

  .footer-links {
    align-items: center;
  }

  .footer-section {
    width: 100%;
  }
}


/* ================================
   📱 Responsiv
================================ */
@media (max-width: 768px) {
  .nav-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .nav-item {
    position: static !important;
    width: 70vw;
    max-width: 300px;
    text-align: center;
    margin: 8px 0;
  }

}
