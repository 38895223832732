@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@600&display=swap');

/* ================================
   🤖 Futuristisk Tech Bakgrund
================================ */
.view-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: radial-gradient(ellipse at center, #0d0d0d 0%, #090909 100%);
  background-attachment: fixed;
  overflow: hidden;
  z-index: 0;
}

/* ================================
   📍 Tech Navigeringspositionering
================================ */
.nav-item.about {
  top: 20%;
  left: 8%;
  transform: rotate(-1deg);
}

.nav-item.education {
  top: 38%;
  right: 10%;
  transform: rotate(0.5deg);
}

.nav-item.experience {
  bottom: 32%;
  left: 16%;
  transform: rotate(-2deg);
}

.nav-item.contact {
  bottom: 14%;
  right: 12%;
  transform: rotate(1deg);
}

/* ================================
   🧬 Tech-Stil Menyknappar
================================ */
.nav-item {
  position: absolute;
  pointer-events: all;
  padding: 14px 28px;
  border: 2px solid rgba(0, 255, 255, 0.3);
  border-radius: 12px;
  background: linear-gradient(160deg, #1a1a1a 0%, #0c0c0c 100%);
  backdrop-filter: blur(2px);
  color: #00ffee;
  font-weight: 600;
  font-family: 'Orbitron', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  box-shadow:
    inset 0 0 10px rgba(0, 255, 255, 0.1),
    0 0 18px rgba(0, 255, 255, 0.15);
  transition: all 0.3s ease;
  cursor: pointer;
  z-index: 5;
}

.nav-item:hover {
  background: #111111;
  border-color: #00ffff;
  color: #ffffff;
  box-shadow:
    0 0 20px #00ffeeaa,
    inset 0 0 12px #00ffee55;
  transform: scale(1.06);
}

.nav-item.active {
  border-color: #00f0ff;
  background: #0d0d0d;
  color: #ffffff;
  box-shadow:
    inset 0 0 14px #00f0ffaa,
    0 0 10px #00f0ffaa;
  transform: scale(1.1);
}

/* ================================
   📡 Panel-overlay – Kontrollcentral
================================ */
.panel-container {
  display: block;
  position: fixed;
  inset: 0;
  z-index: 999;
  background: linear-gradient(180deg, rgba(0, 15, 20, 0.95), rgba(0, 0, 10, 0.98));
  color: #ccfff9;
  overflow-y: auto;
  padding: 3rem 2rem;
  animation: fadeIn 0.4s ease-in-out;
  font-family: 'Orbitron', sans-serif;
  box-shadow:
    inset 0 0 120px rgba(0, 255, 255, 0.08),
    0 0 60px rgba(0, 255, 200, 0.1);
}

/* ================================
   ⌨️ Fade in animation
================================ */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.96) translateY(20px);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

/* ================================
   🖱 Cursor-toggle från View.js
================================ */
body,
.character-container {
  cursor: none;
}

body.show-cursor,
.character-container.show-cursor {
  cursor: default;
}
