@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@600&display=swap');

/* 🎓 Education Wrapper */
.education-wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 24px;
  background: linear-gradient(160deg, #111319, #0a0c12);
  border-radius: 16px;
  box-shadow: 0 0 24px rgba(0, 255, 255, 0.08);
  position: relative;
  color: #d4faff;
  font-family: 'Orbitron', sans-serif;
  text-align: center;
  overflow: hidden;
}

/* ❌ Close-knapp */
.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: rgba(0, 255, 255, 0.05);
  border: 1px solid rgba(0, 255, 255, 0.3);
  color: #00faff;
  border-radius: 8px;
  font-size: 18px;
  padding: 6px 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(4px);
}

.close-btn:hover {
  background: rgba(0, 255, 255, 0.1);
  box-shadow: 0 0 10px #00eaffaa;
}

/* 🧠 Rubriker och instruktion */
h2 {
  color: #00ffff;
  font-size: 1.8rem;
  margin-bottom: 10px;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.4);
}

.instruction {
  font-style: italic;
  color: #aafaff;
  margin-bottom: 24px;
  font-size: 1rem;
  text-shadow: 0 0 6px rgba(0, 255, 255, 0.1);
}

/* 🎮 Connect Four-bana */
.connect-board {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 28px;
  background: rgba(255, 255, 255, 0.03);
  padding: 12px;
  border-radius: 14px;
  border: 1px solid rgba(0, 255, 255, 0.15);
  box-shadow: 0 0 16px rgba(0, 255, 255, 0.05);
}

.row {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.cell {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: inset 0 0 4px rgba(0, 255, 255, 0.15);
}

.cell:hover {
  background-color: rgba(255, 255, 255, 0.08);
}

.cell.player {
  background-color: rgba(0, 191, 255, 0.25);
}

.cell.bot {
  background-color: rgba(255, 77, 77, 0.3);
}

.cell span {
  font-size: 20px;
}

/* ✅ Matchresultat */
.game-result {
  margin-top: 20px;
  animation: fadeIn 0.5s ease-in-out;
}

.game-result p {
  font-size: 1.4rem;
  color: #00ff88;
  margin-bottom: 10px;
}

.game-result button {
  background-color: transparent;
  border: 1px solid #00ffffaa;
  padding: 10px 20px;
  border-radius: 8px;
  color: #00ffff;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(3px);
}

.game-result button:hover {
  background-color: rgba(0, 255, 255, 0.1);
  box-shadow: 0 0 12px #00ffff55;
}

/* 🎓 Utbildningsinfo */
.education-info {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(0, 255, 255, 0.2);
  padding: 24px;
  border-radius: 14px;
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.1);
  animation: fadeIn 0.6s ease-in-out;
  margin-top: 30px;
  text-align: left;
}

.education-info h3 {
  color: #00ffff;
  font-size: 1.5rem;
  margin-bottom: 16px;
}

.education-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.edu-card {
  display: flex;
  align-items: center;
  gap: 16px;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(0, 255, 255, 0.1);
  border-radius: 12px;
  padding: 12px;
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.05);
  transition: transform 0.3s ease;
}

.edu-card:hover {
  transform: scale(1.015);
  box-shadow: 0 0 16px rgba(0, 255, 255, 0.15);
}

.edu-card img {
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 6px rgba(0, 255, 255, 0.08);
  transition: all 0.3s ease;
  opacity: 0;
}

.edu-card img.loaded {
  opacity: 1;
}

.edu-card img:hover {
  transform: scale(1.05);
  box-shadow: 0 0 12px #00ffff88;
}

.edu-text h4 {
  font-size: 1.1rem;
  margin: 0 0 4px;
  color: #00ffff;
}

.edu-text p {
  margin: 0;
  color: #ccc;
  font-size: 0.95rem;
}

/* 🎉 Unlock-grattis */
.congrats-msg {
  font-size: 1.25rem;
  font-weight: 500;
  color: #00ffcc;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 18px;
  animation: fadeIn 1s ease-in-out;
  text-shadow: 0 0 8px rgba(0, 255, 204, 0.3);
}

/* 🌀 Animation */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* 📱 Mobilanpassning */
@media (max-width: 600px) {
  .education-wrapper {
    padding: 24px 12px;
  }

  h2 {
    font-size: 1.45rem;
  }

  .instruction {
    font-size: 0.95rem;
  }

  .cell {
    width: 38px;
    height: 38px;
  }

  .game-result p {
    font-size: 1.1rem;
  }

  .game-result button {
    padding: 7px 14px;
    font-size: 0.92rem;
  }

  .edu-card {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    gap: 12px;
    padding: 14px;
  }

  .edu-card img {
    width: 100%;
    height: auto;
    max-height: 160px;
    object-fit: cover;
    border-radius: 10px;
  }

  .edu-text h4 {
    font-size: 1.05rem;
  }

  .edu-text p {
    font-size: 0.9rem;
  }
}
